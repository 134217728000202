<template>
  <AccountWrapper>
    <cds-cards>
      <template v-slot:title>
        <div class="setting-card-title">
          <cds-heading as="h4">Cài đặt tài khoản</cds-heading>
          <span>Cập nhật username và quản lý tài khoản của bạn</span>
        </div>
      </template>
      <a-row ju>
        <a-col :xs="24">
          <a-form
              ref="formModal"
              :model="formState"
              labelAlign="left"
              :rules="rules"
              :validateTrigger="['change', 'blur']"
              layout="vertical"
              @finish="onFinish"
          >
            <div class="account-form-top">
              <a-row type="flex" justify="center">
                <a-col :xxl="10" :lg="16" :md="18" :xs="24">
                  <div class="account-form">
                    <a-form-item label="Username" name="username">
                      <cds-input class="w-100 ant-input-sm" v-model:value="formState.username" :maxlength="50"
                                 show-count
                                 size="small"
                                 placeholder="Username"/>
                    </a-form-item>
                    <a-form-item label="Email" name="email">
                      <cds-input class="w-100 ant-input-sm" v-model:value="formState.email" :maxlength="100" show-count
                                 size="small"
                                 placeholder="Email"/>
                    </a-form-item>

                    <hr class="footer">

                    <div class="w-100 text-center">
                      <cds-button
                          key="submit"
                          type="primary"
                          size="small"
                          type-html="submit"
                      >
                        <cds-feather-icons class="mr-10" type="save"/>
                        Lưu
                      </cds-button>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-form>
        </a-col>
      </a-row>
    </cds-cards>
  </AccountWrapper>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import { asyncExistedRule, emailRule, maxlength, required } from "@/util/validation";
import { FORM_MODE, GENDERS, STATUS } from "@/util/common-constant";
import ConstantAPI from "@/config/ConstantAPI";

const Account = defineComponent({
  name: "Account",
  setup() {
    const { state, dispatch } = useStore();
    const formState = reactive(state.auth.userInfo);
    const formMode = computed(() => state.user.formMode);
    const departments = computed(() => []);
    const roles = computed(() => state.role.list);
    onMounted(() => {
      dispatch('cp/getAll').then();
    });
    const rules = {
      username: [
        required('Username'),
        maxlength(50, 'Username'),
        asyncExistedRule('Username', ConstantAPI.user.CHECK_USERNAME_EXISTED.url, {userId: formState.userId}),
      ],
      email: [required('Email'), emailRule()],
    };
    const formModal = ref(null);
    const fieldNames = { label: 'name', key: 'value' };
    const disabled = formMode.value === FORM_MODE.VIEW;
    const reset = () => dispatch('user/setVisible', false);
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState));
        await dispatch('updateProfile', data);
        // eslint-disable-next-line no-empty
      } catch {

      }
    };
    return {
      formState,
      rules,
      departments,
      fieldNames,
      formModal,
      onFinish,
      reset,
      disabled,
      roles,
      status: STATUS,
      genders: GENDERS,
      formMode,
      FORM_MODE: FORM_MODE
    };
  },
});

export default Account;
</script>
