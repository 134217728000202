<template>
  <div class="pt-4">
    <cds-main>
      <a-row :gutter="25">
        <a-col :xxl="6" :lg="8" :md="10" :xs="24">
          <ProfileAuthorBox>
            <author-box />
          </ProfileAuthorBox>
        </a-col>
        <a-col :xxl="18" :lg="16" :md="14" :xs="24">
          <SettingWrapper>
<!--            <CoverSection />-->
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>
          </SettingWrapper>
        </a-col>
      </a-row>
    </cds-main>
  </div>
</template>

<script>
import { ProfileAuthorBox, SettingWrapper } from './overview/style';
import AuthorBox from './overview/ProfileAuthorBox';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Settings',
  components: {
    SettingWrapper,
    AuthorBox,
    ProfileAuthorBox,
  },
  data() {
    return {};
  },
});
</script>
