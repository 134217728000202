<template>
  <cds-cards headless>
    <div class="author-info">
      <figure>
        <a-spin :spinning="uploading">
          <a-avatar shape="square" :size="164"
                    :src="userInfo.avatar">
          </a-avatar>
        </a-spin>
        <a-button class="btn-upload" type="primary" shape="circle" @click="$refs.upload.click()">
          <cds-feather-icons type="camera" size="16"/>
        </a-button>
        <input ref="upload" type="file" @change="handleChange" style="display: none">
      </figure>
      <figcaption>
        <div class="info">
          <cds-heading as="h4">{{ userInfo.fullName }}</cds-heading>
<!--          <p>{{ userInfo.department.name }}</p>-->
        </div>
      </figcaption>
    </div>
    <nav class="settings-menmulist">
      <ul>
        <li>
          <router-link :to="{ name: 'set-profile' }">
            <cds-feather-icons type="user" size="14"/>
            Thông tin cá nhân
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'set-account' }">
            <cds-feather-icons type="settings" size="14"/>
            Cài đặt tài khoản
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'set-password' }">
            <cds-feather-icons type="key" size="14"/>
            Đổi mật khẩu
          </router-link>
        </li>
      </ul>
    </nav>
  </cds-cards>
</template>
<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: 'AuthorBox',
  components: {},
  setup() {
    const { state, dispatch } = useStore();
    const userInfo = computed(() => state.auth.userInfo);
    const uploading = computed(() => state.auth.uploading);
    const handleChange = (file) => {
      if (file.target.files && file.target.files.length) {
        console.log(file.target.files[0])
        dispatch('changeAvatar', file.target.files[0])
      }
    };
    return {
      userInfo,
      handleChange,
      uploading
    }
  }
});
</script>

<style>
.btn-upload {
  transform: translateY(-33px);
  border: 1px solid white;
}
</style>
