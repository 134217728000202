<template>
  <a-spin size="large" :spinning="loading">
    <cds-cards>
      <template v-slot:title>
        <div class="setting-card-title">
          <cds-heading as="h4">Thông tin cá nhân</cds-heading>
          <span>Cập nhật thông tin cá nhân của bạn</span>
        </div>
      </template>
      <a-row type="flex" justify="center">
        <a-col :xl="16" :lg="16" :xs="24">
          <a-form
              ref="formModal"
              :model="formState"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              labelAlign="left"
              :rules="rules"
              :validateTrigger="['change', 'blur']"
              @finish="onFinish"
          >
            <a-form-item label="Họ và tên" name="fullName">
              <cds-input class="w-100 ant-input-sm" v-model:value="formState.fullName" :maxlength="50" show-count
                         size="small"
                         placeholder="Họ và tên"/>
            </a-form-item>
            <a-form-item label="Mô tả" name="description">
              <cds-input class="w-100 ant-input-sm" v-model:value="formState.description" :maxlength="100" show-count
                         size="small"
                         placeholder="Mô tả"/>
            </a-form-item>
            <a-form-item label="Số điện thoại" name="phone">
              <cds-input class="w-100 ant-input-sm" v-model:value="formState.phone" :maxlength="12" show-count
                         size="small"
                         placeholder="Số điện thoại"/>
            </a-form-item>
            <a-form-item label="Giới tính" name="gender">
              <cds-select :options="genders" placeholder="Giới tính"
                          v-model:value="formState.gender"/>
            </a-form-item>
            <a-form-item label="Ngày sinh" name="birthday">
              <cds-date-picker
                  v-model:value="formState.birthday"
                  :disabledDate="disabledToDate"
                  placeholder="Ngày sinh">
              </cds-date-picker>
            </a-form-item>
<!--            <a-form-item label="Địa chỉ" name="address">-->
<!--              <cds-input class="w-100 ant-input-sm" v-model:value="formState.address" :maxlength="250" show-count-->
<!--                         size="small"-->
<!--                         placeholder="Địa chỉ"/>-->
<!--            </a-form-item>-->
            <a-form-item label="Đối tác" name="cpId">
              <cds-select disabled :options="cps" v-model:value="formState.cpId"/>
            </a-form-item>
            <a-form-item label="Trạng thái" name="status">
              <cds-select disabled :options="status" placeholder="Trạng thái"
                          v-model:value="formState.status"/>
            </a-form-item>

            <hr class="footer">

            <div class="w-100 text-center">
              <cds-button
                  key="submit"
                  type="primary"
                  size="small"
                  type-html="submit"
              >
                <cds-feather-icons class="mr-10" type="save"/>
                Lưu
              </cds-button>
            </div>
          </a-form>
        </a-col>
      </a-row>
    </cds-cards>
  </a-spin>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from "vuex";
import _ from "lodash";
import { maxlength, numberRule, required } from "@/util/validation";
import { FORM_MODE, GENDERS, STATUS } from "@/util/common-constant";
import { greaterThanCurrent } from "@/util/common-utils";

const Profile = defineComponent({
  name: 'Profile',
  setup() {
    const { state, dispatch } = useStore();
    const formState = reactive(state.auth.userInfo);
    const formMode = computed(() => state.user.formMode);
    const cps = computed(() => state.cp.listAll);
    const roles = computed(() => state.role.list);
    const loading = computed(() => state.auth.loading);
    onMounted(() => {
      dispatch('cp/getAll').then();
    });
    const rules = {
      description: [maxlength(100, 'Mô tả')],
      phone: [required('Số điện thoại'), numberRule('Số điện thoại'), maxlength(12, 'Số điện thoại')],
      gender: [required('Giới tính')],
      password: [required('Mật khẩu')],
      fullName: [required('Họ và tên'), maxlength(50, 'Họ và tên')],
      address: [maxlength(250, 'Địa chỉ')],
    };
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const formModal = ref(null);
    const fieldNames = { label: 'name', key: 'value' };
    const disabled = formMode.value === FORM_MODE.VIEW;
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState));
        await dispatch('updateProfile', data);
        // eslint-disable-next-line no-empty
      } catch {

      }
    };
    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      cps,
      fieldNames,
      formModal,
      onFinish,
      loading,
      disabled,
      roles,
      status: STATUS,
      genders: GENDERS,
      formMode,
      FORM_MODE: FORM_MODE,
      disabledToDate: greaterThanCurrent
    };
  },
});

export default Profile;
</script>
