<template>
  <ChangePasswordWrapper>
    <cds-cards>
      <template v-slot:title>
        <div class="setting-card-title">
          <cds-heading as="h4">Cài đặt mật khẩu</cds-heading>
          <span>Thay đổi mật khẩu của bạn</span>
        </div>
      </template>
      <a-row type="flex" justify="center">
        <a-col :lg="12" :sm="20" :xs="24">
          <BasicFormWrapper>
            <a-form :model="formState" @finish="handleFinish" layout="vertical" :rules="rules">
              <a-form-item name="oldPassword" label="Mật khẩu cũ">
                <a-input-password v-model:value="formState.oldPassword" show-count :maxlength="30"/>
              </a-form-item>
              <a-form-item name="newPassword" label="Mật khẩu mới">
                <a-input-password size="small" v-model:value="formState.newPassword" show-count :maxlength="30"/>
              </a-form-item>
              <a-form-item>
                <div class="setting-form-actions text-center">
                  <cds-button htmlType="submit" type="primary">Đổi mật khẩu</cds-button>
                </div>
              </a-form-item>
            </a-form>
          </BasicFormWrapper>
        </a-col>
      </a-row>
    </cds-cards>
  </ChangePasswordWrapper>
</template>

<script>
import { ChangePasswordWrapper } from './style';
import { BasicFormWrapper } from '../../../styled';
import { defineComponent, reactive } from 'vue';
import { maxlength, minlength, required } from "@/util/validation";
import { useStore } from "vuex";

const Password = defineComponent({
  name: 'Password',
  components: {
    ChangePasswordWrapper,
    BasicFormWrapper
  },
  data() {
    const formState = reactive({
      oldPassword: '',
      newPassword: '',
    });
    const rules = {
      oldPassword: [required('Mật khẩu cũ'), minlength(6, 'Mật khẩu cũ'), maxlength(100, 'Mật khẩu cũ')],
      newPassword: [required('Mật khẩu mới'), minlength(6, 'Mật khẩu mới'), maxlength(100, 'Mật khẩu mới')],
    };
    const { dispatch } = useStore();

    const handleFinish = () => {
      dispatch('changePassword', formState)
    };

    return {
      formState,
      rules,
      handleFinish,
    };
  },
});

export default Password;
</script>
